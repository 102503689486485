<template>
  <div>
    <div class="form--group row" :class="{ 'has-error': validation.hasError('province') }">
      <div class="col-12 col-lg-3">
        <label for="province-id-field">
          <span>{{ $t('general.province') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-12 col-lg-6">
        <multiselect
          id="province-id-field"
          name="province"
          v-model="province"
          :options="provinces"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('province') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('general.province')"
          :disabled="readOnly"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('province')">{{
          validation.firstError('province')
        }}</span>
      </div>
    </div>
    <div
      v-show="openCity"
      class="form--group row"
      :class="{ 'has-error': validation.hasError('city') }"
    >
      <div class="col-12 col-lg-3">
        <label for="city-id-field">
          <span>{{ $t('general.city') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="col-12 col-lg-6">
        <multiselect
          id="city-id-field"
          name="city"
          v-model="city"
          :options="cities"
          track-by="id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :class="validation.hasError('city') ? 'is-invalid' : ''"
          :show-labels="true"
          :placeholder="$t('general.city')"
          :disabled="readOnly"
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('city')">{{
          validation.firstError('city')
        }}</span>
      </div>
    </div>
    <div v-show="openDistrict" class="my-4">
      <!--      <div class="form&#45;&#45;group row">-->
      <!--        <div class="col-12 col-lg-3">-->
      <!--          <label for="district-id-field">-->
      <!--            <span>{{ $t('general.district') }}</span>-->
      <!--            <span class="required">*</span>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--        <div class="col-12 col-lg-6">-->
      <!--          <multiselect-->
      <!--            name="district"-->
      <!--            id="district-id-field"-->
      <!--            v-model="district"-->
      <!--            :options="districts"-->
      <!--            track-by="id"-->
      <!--            label="name"-->
      <!--            :searchable="true"-->
      <!--            :close-on-select="true"-->
      <!--            :show-labels="true"-->
      <!--            :placeholder="$t('general.district')"-->
      <!--            :disabled="readOnly"-->
      <!--          >-->
      <!--          </multiselect>-->
      <!--          <span class="val-error" v-if="validation.hasError('district')">{{-->
      <!--            validation.firstError('district')-->
      <!--          }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="form&#45;&#45;group row">-->
      <!--        <div class="col-12 col-lg-3">-->
      <!--          <label for="village-id-field">-->
      <!--            <span>{{ $t('general.village') }}</span>-->
      <!--            <span class="required">*</span>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--        <div class="col-12 col-lg-6">-->
      <!--          <multiselect-->
      <!--            id="village-id-field"-->
      <!--            name="village"-->
      <!--            v-model="village"-->
      <!--            :options="villages"-->
      <!--            track-by="id"-->
      <!--            label="name"-->
      <!--            :searchable="true"-->
      <!--            :close-on-select="true"-->
      <!--            :show-labels="true"-->
      <!--            :placeholder="$t('general.village')"-->
      <!--            :disabled="readOnly"-->
      <!--          >-->
      <!--          </multiselect>-->
      <!--          <span class="val-error" v-if="validation.hasError('village')">{{-->
      <!--            validation.firstError('village')-->
      <!--          }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="form--group row">
        <div class="col-12 col-lg-3">
          <label for="area-id-field">
            <span>{{ $t('general.area') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="col-12 col-lg-6">
          <multiselect
            id="area-id-field"
            name="area"
            v-model="area"
            :options="areas"
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            :placeholder="$t('general.area')"
            :disabled="readOnly"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('area')">{{
            validation.firstError('area')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';

export default {
  name: 'regional-section',
  props: ['readOnly'],
  components: { Multiselect },
  computed: {
    ...mapState({
      provinces: (state) => state.listing.form.regional.provinces,
      cities: (state) => state.listing.form.regional.cities,
      districts: (state) => state.listing.form.regional.districts,
      villages: (state) => state.listing.form.regional.villages,
      areas: (state) => state.listing.form.regional.areas,
      openDistrict: (state) => state.listing.form.regional.openDistrict,
      openCity: (state) => state.listing.form.regional.openCity,
    }),
    province: {
      get() {
        return this.$store.state.listing.form.regional.province;
      },
      set(value) {
        this.$store.dispatch('listing/form/regional/changeProvince', value);
        this.$store.dispatch('listing/form/regional/getCities', value);
      },
    },
    city: {
      get() {
        return this.$store.state.listing.form.regional.city;
      },
      set(value) {
        this.$store.dispatch('listing/form/regional/changeCity', value);
        // this.$store.dispatch('listing/form/regional/getDistricts', value);
        this.$store.dispatch('listing/form/regional/getAreas', value);
      },
    },
    district: {
      get() {
        return this.$store.state.listing.form.regional.district;
      },
      set(value) {
        this.$store.dispatch('listing/form/regional/changeDistrict', value);
        this.$store.dispatch('listing/form/regional/getVillages', value);
      },
    },
    village: {
      get() {
        return this.$store.state.listing.form.regional.village;
      },
      set(value) {
        this.$store.dispatch('listing/form/regional/changeVillage', value);
      },
    },
    area: {
      get() {
        return this.$store.state.listing.form.regional.area;
      },
      set(value) {
        this.$store.dispatch('listing/form/regional/changeArea', value);
      },
    },
  },
  methods: {
    validate() {
      // console.log('validate province')
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },
  validators: {
    province(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.province.required'));
    },
    city(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.city.required'));
    },
    // district(value) {
    //   return Validator.value(value).required(this.$i18n.t('errors.addListing.district.required'));
    // },
    // village(value) {
    //   return Validator.value(value).required(this.$i18n.t('errors.addListing.village.required'));
    // },
    area(value) {
      return Validator.value(value).required(this.$i18n.t('errors.addListing.area.required'));
    },
  },
};
</script>

<style scoped></style>
